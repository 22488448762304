export const ETHNAM_URL = `${import.meta.env.VITE_8122_URL}`;

// 旧パートナー画面
export const PARTNER_ETHNAM_HOME_URL = `${ETHNAM_URL}/partner`;
export const PARTNER_ETHNAM_PASSWORD_RESET_URL = `${ETHNAM_URL}/partner/?action_owner_OWNERACCOUNTpasswdreset=true`;
export const PARTNER_ETHNAM_GROUP_SUMMARY_URL = (groupId: string): string =>
  `${ETHNAM_URL}/partner/?g=${groupId}`;
export const PARTNER_ETHNAM_EVENT_SUMMARY_URL = (eventId: string): string =>
  `${ETHNAM_URL}/partner/?e=${eventId}`;

export const PRIVACY_POLICY_URL = 'https://sencorp.co.jp/privacypolicy/';

const PARTNER_HELP_SITES_URL =
  'https://sites.google.com/sencorp.co.jp/partnerhelp';

export const PARTNER_HELP_SITES_URLS = {
  HOME: PARTNER_HELP_SITES_URL,
  CONTACT: `${PARTNER_HELP_SITES_URL}/${encodeURIComponent('お問い合わせ')}`,
  MANUAL_CATEGORIZE_FOLDER: `${PARTNER_HELP_SITES_URL}/${encodeURIComponent('操作マニュアル')}/${encodeURIComponent('事前準備-フォルダ分け')}`,
};

export const PARTNER_FEEDBACK_SURVEY_FORM = `${import.meta.env.VITE_PARTNER_FEEDBACK_SURVEY_FORM}`;

export const PARTNER_GTM_URL = `${import.meta.env.VITE_PARTNER_GTM_URL}`;

// 新パートナー画面
export const DASHBOARD_HOME_URL = '/';
export const LOGIN_URL = '/login';
export const PHOTO_UPLOADER_SELECT_GROUP_URL = '/photo-uploader/groups';
export const PHOTO_UPLOADER_SELECT_EVENT_URL = (groupId: string): string =>
  `/photo-uploader/groups/${groupId}/events`;
export const PHOTO_UPLOADER_PRE_SETTINGS_URL = (
  groupId: string,
  eventId: string,
): string => `/photo-uploader/groups/${groupId}/events/${eventId}/pre-settings`;
export const PHOTO_UPLOADER_PHOTO_CHECK_URL = (
  groupId: string,
  eventId: string,
): string => `/photo-uploader/groups/${groupId}/events/${eventId}/photo-check`;
export const PHOTO_UPLOADER_UPLOADING_URL = (
  groupId: string,
  eventId: string,
): string => `/photo-uploader/groups/${groupId}/events/${eventId}/uploading`;
export const PHOTO_UPLOADER_COMPLETE_URL = (
  groupId: string,
  eventId: string,
): string => `/photo-uploader/groups/${groupId}/events/${eventId}/complete`;
export const PHOTO_UPLOADER_FAILURE_URL = (
  groupId: string,
  eventId: string,
): string => `/photo-uploader/groups/${groupId}/events/${eventId}/failure`;
